<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
      {{alertMessage}}
      </v-alert>
    </div>

    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>New User </h1>
          </div>
          <v-form ref="contractEmployer" v-model="valid">
            <v-row no-gutters>
              <v-col cols="12" v-if="showError">
                <p class="alert-error mb-3">{{errorMessage}}</p>
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.username" :label="'Email'" />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.firstName" :label="'First Name'" :type="'text'"  />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.middleName" :label="'Middle Name'" />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.lastName" :label="'Last Name'" :type="'text'"/>
              </v-col>

              <v-col cols="12" class="d-flex justify-end">
                <div class="btn-group">
                  <Button
                    :label="'Submit'"
                    :btnType="'Submit'"
                    @onClick="onSave"
                    :disabled="!valid"
                    :isLoading="isLoading"
                    class="mr-4"
                  />
                  <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import { contractEmployeeruser } from '@/services'

export default {
  components: {
    TextField,
    Button
  },
  data () {
    return {
      search: '',
      formData: {
        firstName: '',
        lastName: '',
        middleName: '',
        username: '',
        password: ''
        // ContractEmployerId: this.$route.params.id
      },
      valid: false,
      showError: false,
      isLoading: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      errorMessage: ''
    }
  },
  computed: {},
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isLoading = true
      this.errorMessage = ''
      this.showError = false

      contractEmployeeruser.isUserExist(this.formData.username).then(result => {
        if (result.data === true) {
          this.valid = !result.data
          this.showError = result.data
          this.errorMessage = 'Email address already exist'
        } else {
          contractEmployeeruser.createContractEmployer(this.formData).then(() => {
            this.showAlertMessage('User successfully created', 'success')
          }).catch(() => {
            this.showAlertMessage('Unable to create user', 'error')
          }).finally(() => {
            this.isLoading = false
            this.onReset()
          })
        }
      })
    },
    onReset () {
      this.$refs.contractEmployer.reset()
    }
  },
  mounted () {},
  watch: {
    'formData.username': function (value) {
      this.showError = false
      this.errorMessage = ''
      const isEmailValid = /.+@.+\..+/.test(value)
      if (isEmailValid) {
        contractEmployeeruser.isUserExist(value).then(result => {
          this.valid = !result.data
          this.showError = result.data
          this.errorMessage = 'Email address already exist'
        })
      }
    }
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.btn-group{
  display: inline-flex;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
 .alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
